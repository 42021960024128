import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import Lights from "../Components/Lights";

const PolitiquesDeConf = () => {
  return (
    <div className="mentions">
      <Modal />
      <Header />
      <h3 className="pagesTitle">Politiques de Confidentialités</h3>
      <p>
        Notre engagement envers la sécurité et la confidentialité de vos
        informations personnelles est essentiel, reflétant notre sérieux et
        notre crédibilité. La présente déclaration de confidentialité illustre
        notre détermination à adhérer aux normes de protection des données, en
        particulier celles stipulées par le Règlement général sur la protection
        des données (RGPD). Cette déclaration a pour objectif de vous clarifier
        comment et pourquoi nous traitons vos informations dans le cadre de nos
        prestations. Cette déclaration concerne toute personne utilisant nos
        services, postulant chez nous ou visitant notre site, sans considération
        de domicile. Elle se limite aux opérations de traitement que nous gérons
        et exclut celles réalisées par nos clients via les fonctionnalités de
        notre service Goview. Pour comprendre comment nos clients utilisent vos
        données via notre service, veuillez les contacter directement. Nous
        traitons vos données personnelles pour diverses raisons et sur diverses
        bases juridiques, y compris pour vous permettre de naviguer sur notre
        site, utiliser nos services et nous permettre de répondre à vos
        requêtes, dans le cadre de nos conditions de vente et d'utilisation et
        notre intérêt légitime à vous offrir un service de qualité. Nous
        traitons également vos données pour des communications marketing,
        l'interaction sur les réseaux sociaux, l'envoi de newsletters, les
        processus de recrutement, l'amélioration continue de nos services et la
        sécurité des données, conformément à nos obligations légales, nos
        conditions générales et notre intérêt légitime. En outre, nous utilisons
        des "Cookies" pour lesquels nous vous renvoyons à notre "Politique
        Cookies" pour plus de détails. Vos données sont recueillies directement
        lors de votre interaction avec notre site et nos services. Nous nous
        engageons à ne traiter vos données que pour les motifs explicités et
        assurons qu'elles ne seront jamais vendues. Vous restez cependant
        responsables des informations personnelles partagées volontairement sur
        les réseaux sociaux. Nous avons établi un récapitulatif des catégories
        de données personnelles que nous recueillons et de la durée de leur
        conservation. Pour des détails spécifiques sur ces durées, notre contact
        est goviewapp@contact.fr Ces données incluent des informations
        professionnelles et de contact, des données économiques et financières,
        des informations utilisées pour la prospection commerciale, des données
        de connexion, des détails fournis dans les CV et lettres de motivation,
        et des Cookies, chacune avec des périodes de conservation définies. Une
        fois ces périodes expirées, nous effaçons vos données de manière
        irréversible pour protéger votre vie privée. Dans le cas de litiges,
        nous sommes tenus de conserver vos données pour la durée nécessaire à la
        résolution du différend.Les lois en vigueur vous confèrent un éventail
        de droits relatifs à vos données personnelles, que vous pouvez exercer
        gratuitement et à tout moment pour influer sur l’utilisation que nous
        faisons de vos données : Le droit de consulter et d’obtenir une copie de
        vos données personnelles, tant que cette requête ne va pas à l’encontre
        du secret des affaires ou de la confidentialité. Le droit de corriger
        des données personnelles erronées, obsolètes ou incomplètes. Le droit de
        refuser le traitement de vos données personnelles à des fins de
        prospection commerciale. Le droit de solliciter la suppression de vos
        données personnelles qui ne sont pas indispensables au fonctionnement de
        nos services (connu sous le nom de "droit à l'oubli"). Le droit de
        demander une restriction du traitement de vos données personnelles, ce
        qui fige l’utilisation de vos données en cas de contestation sur la
        légitimité d’un traitement. Le droit à la portabilité de vos données,
        vous permettant de reprendre et de transférer une partie de vos données
        personnelles d’un système d’information à un autre. Le droit de préciser
        les instructions concernant le devenir de vos données après votre décès,
        directement ou via une personne de confiance. Pour que votre demande
        soit considérée, elle doit être formulée par vous-même à l'adresse
        [indiquée], et toute demande non conforme à cette procédure ne pourra
        être traitée. Nous nous réservons le droit de demander une preuve
        d'identité pour vérifier l'identité du demandeur. Nous pouvons refuser
        de répondre à des demandes jugées excessives ou sans fondement,
        notamment si elles sont récurrentes. Seules les personnes autorisées
        auront accès à vos données pour la mise en œuvre de nos services,
        incluant notre personnel affecté au service, à la comptabilité, au
        marketing ou à la sécurité. Pour protéger vos données, nous déployons
        toutes les mesures techniques et organisationnelles nécessaires pour
        prévenir toute forme de compromission de vos données, telles que la
        formation du personnel, le contrôle des accès, l'usage de mots de passe
        robustes et de logiciels antivirus, ainsi que le recours à des
        connexions sécurisées. Vos données ne seront transférées hors de l’Union
        européenne que si cela est strictement nécessaire et de façon
        exceptionnelle, en veillant toujours à ce qu'elles soient hébergées sur
        le sol européen et que nos prestataires respectent les garanties
        adéquates pour la protection de vos données. Vous avez également le
        droit de contacter la CNIL pour toute réclamation. Notre politique de
        confidentialité est susceptible d'évoluer pour se conformer aux
        nouvelles réglementations et aux potentiels nouveaux traitements de
        données que nous pourrions initier. Toute modification vous sera
        communiquée.
      </p>
      <Lights />
      <Footer />
    </div>
  );
};

export default PolitiquesDeConf;
