import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Lights from "../Components/Lights";
import Menu from "../Components/Menu";
import Footer from "../Components/Footer";
import arrow from "../assets/arrow.svg";
import underline from "../assets/underline.svg";
import CookieConsent from "react-cookie-consent";
import Modal from "../Components/Modal";
import NotificationBar from "../Components/NotificationBar";

const Home = () => {
  const [homePage, setHomePage] = useState(true);
  const [userS, setUserSearch] = useState("");
  const [loaderIsVisible, setLoaderVisible] = useState(false);
  const [products, setProducts] = useState([]);

  const fetchData = async (searchTerm) => {
    try {
      const response = await fetch(
        `https://charlize-api.onrender.com/api?searchTerm=${searchTerm}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Erreur réseau lors du fetch.");
      }

      const rawText = await response.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(rawText, "text/html");

      const products = [...doc.querySelectorAll(".producto")]
        .slice(0, 20)
        .map((el, index) => {
          const countryTagMap = {
            fr: "lespepites01-21",
            es: "lespepitese0b-21",
            it: "lespepites063-21",
            de: "lespepitesd03-21",
            be: "lespepitesb0e-21",
            uk: "lespepitesu02-21",
          };

          const test = el.querySelector(".listadoPrecios");

          const priceElements = test ? test.querySelectorAll(".precio") : "non";

          const urlElements = el.querySelectorAll(".bandera");
          const titleElement = el.querySelector(".titulo"); // Assuming there's only one title per product
          const imageElement = el.querySelector(".ima img");

          const title = titleElement ? titleElement.textContent : null;
          const imageUrl = imageElement
            ? imageElement.getAttribute("src")
            : null;
          urlElements.forEach((url) => {
            return url;
          });
          const prices = [...urlElements].map((urlEl, idx) => {
            const url = urlEl.getAttribute("href");

            const match = url.match(
              /https:\/\/www\.amazon\.(\w{2}|\w{2}\.\w{2})\/dp\/(\w+)/
            );

            let asin, countryCode, newUrl;

            if (match && match[1] && match[2]) {
              countryCode = match[1];
              asin = match[2];
              const domain = countryCode === "uk" ? "co.uk" : countryCode;
              newUrl = `https://www.amazon.${domain}/dp/${asin}?tag=${countryTagMap[countryCode]}&linkCode=ogi&th=1`;
            }

            const price = priceElements[idx]
              ? parseFloat(priceElements[idx].textContent)
              : null;

            return {
              asin: asin,
              country: countryCode,
              price: price,
              url: newUrl,
            };
          });
          const validPrices = prices.filter(
            (priceObj) =>
              priceObj.country !== "us" &&
              priceObj.country !== "ca" &&
              priceObj.price !== null &&
              priceObj.price !== undefined &&
              !isNaN(priceObj.price)
          );

          return {
            id: `${prices.asin}-${index}`,
            title: title,
            imageUrl: imageUrl,
            prices: validPrices,
          };
        });

      const filteredProducts = products.filter((product) => {
        return product.title !== null;
      });
      return filteredProducts;
    } catch (error) {
      console.error("Il y a eu un problème avec l'opération fetch:", error);
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      fetchData("Iphone").then((data) => setProducts(data));
    };
    initialFetch();
  }, []);

  // useEffect(() => {
  //   const handleScroll = async () => {
  //     if (nmbrRequest < 1 && userS.length > 0 && !homePage) {
  //       setLoaderVisible(true);
  //       setNmbrRequest(nmbrRequest + 1);
  //       const newProducts = await fetchProductData(userS, 2);
  //       setProducts((prevProducts) => {
  //         const combinedProducts = [...prevProducts, ...newProducts];
  //         return combinedProducts;
  //       });
  //     } else {
  //     }
  //   };

  //   const eventListenerOptions = { passive: true };

  //   const scrollEvent = "ontouchstart" in window ? "touchmove" : "scroll";

  //   window.addEventListener(scrollEvent, handleScroll, eventListenerOptions);

  //   return () => {
  //     window.removeEventListener(
  //       scrollEvent,
  //       handleScroll,
  //       eventListenerOptions
  //     );
  //   };
  // }, [userS, nmbrRequest, loaderIsVisible, homePage, products]);

  const handleRequest = async (e) => {
    e.preventDefault();
    setHomePage(false);
    setProducts([]);
    // setNmbrRequest(0);
    setLoaderVisible(false);
    const userSearch = e.target.elements.search.value;
    setUserSearch(userSearch);
    const searchedProducts = await fetchData(userSearch);
    setProducts(searchedProducts);
  };

  return (
    <>
      <Modal />
      <Header />
      <NotificationBar />
      <section className="headerMenu">
        <h1>
          Trouver le meilleur prix, dans toute l’Europe.
          <img src={underline} alt="underline" className="underlineTitle" />
        </h1>
        <h2>
          Achetez intelligemment au meilleur prix vos produits favoris et
          économisez sur chaque achat, peu importe où vous vous trouvez.
          <img src={arrow} alt="arrow" className="arrowTitle" />
        </h2>
        <form className="searchForm" onSubmit={handleRequest}>
          <input
            type="text"
            placeholder="Dyson, iPhone 14, etc ..."
            id="search"
            autoComplete="off"
            required
          />
          <button type="submit" className="submitButton button">
            <span className="material-symbols-outlined">search</span>
            Rechercher
          </button>
        </form>
        <div className={homePage ? "brandCarousel" : "hideCarousel"}>
          <p>Comparez plus de 500 marques sur notre site web</p>
          <div className="brands">
            <div className="brand-slide">
              <div className="dysonLogo"></div>
              <div className="siemensLogo"></div>
              <div className="rowentaLogo"></div>
              <div className="phillipsLogo"></div>
              <div className="logitechLogo"></div>
            </div>
            <div className="brand-slide">
              <div className="dysonLogo"></div>
              <div className="siemensLogo"></div>
              <div className="rowentaLogo"></div>
              <div className="phillipsLogo"></div>
              <div className="logitechLogo"></div>
            </div>
            <div className="brand-slide">
              <div className="dysonLogo"></div>
              <div className="siemensLogo"></div>
              <div className="rowentaLogo"></div>
              <div className="phillipsLogo"></div>
              <div className="logitechLogo"></div>
            </div>
          </div>
        </div>
      </section>
      <Menu
        data={products}
        loaderIsVisible={loaderIsVisible}
        homePage={homePage}
      />
      <Lights />
      <Footer />
      <CookieConsent
        location="bottom" // Position de la bannière : "bottom" ou "top"
        buttonText="Accepter" // Texte du bouton de consentement
        cookieName="myCookieConsent" // Nom du cookie de consentement (peut être utilisé pour vérifier si le consentement a été donné)
        expires={150} // Durée d'expiration du cookie en jours
        style={{
          background: "white",
          color: "black",
          fontFamily: "Figtree",
          left: "50%",
          marginBottom: "20px",
          transform: "translateX(-50%)",
          width: "90%",
          borderRadius: "8px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: "10px",
          animation: "fadeIn 0.3s ",
        }}
        buttonStyle={{
          background:
            "linear-gradient(228.72deg, #8F14FF 6.71%, #AB50FE 96.21%)",
          color: "#fff",
          borderRadius: "3px",
        }}
        contentStyle={{ fontSize: "16px", fontFamily: "Figtree" }}
      >
        Ce site web utilise des cookies pour améliorer votre expérience 🍪
      </CookieConsent>
    </>
  );
};

export default Home;
