import React from "react";
import Header from "../Components/Header";
import Lights from "../Components/Lights";
import Modal from "../Components/Modal";
import Footer from "../Components/Footer";
import Question from "../Components/Question";

const FAQ = () => {
  const questions = [
    {
      question: "Pourquoi choisir Charlize ?",
      response: `Sur Internet, il est important de noter que les boutiques en ligne, y compris les magasins français et les sites comme Amazon dans d'autres pays européens ou en dehors de la zone européenne, peuvent proposer des produits similaires à des prix très différents. Cette disparité de prix peut être constatée dans diverses catégories telles que la technologie, les outils de jardinage, les appareils ménagers, les jouets et bien d'autres.\n\nChez Charlize, nous mettons à votre disposition un comparateur de prix pour vous aider à réaliser des économies potentielles lors de vos achats en ligne. Grâce à notre site Internet, vous avez la possibilité de trouver les meilleurs prix disponibles, que ce soit en France ou dans d'autres pays européens. Ainsi, vous pouvez bénéficier de tarifs avantageux, même en commandant auprès de boutiques situées en dehors de la France.`,
      text: "long-text",
    },
    {
      question: "Un compte par pays ?",
      response: `Si vous êtes déjà un client d'Amazon, vous avez la possibilité d'utiliser votre compte existant pour accéder aux différentes boutiques internationales et européennes de l'entreprise.\n\nVous pouvez facilement vous connecter en utilisant vos identifiants habituels sur la boutique de votre choix parmi les options disponibles, telles que la boutique française, espagnole, britannique, allemande, italienne, et bien d'autres.\n\nPour ceux qui n'ont pas encore de compte Amazon, nous vous recommandons de vous inscrire d'abord sur la boutique correspondant à votre langue maternelle. Ensuite, vous pourrez effectuer vos recherches via notre site et suivre le processus d'achat habituel pour bénéficier du produit au meilleur prix.\n\nEn vous inscrivant sur la boutique Amazon correspondant à votre langue, vous pourrez profiter d'une expérience adaptée !`,
      text: "long-text",
    },
    {
      question: "Et les frais de livraison ?",
      response: `Les frais de livraison s'avèrent être modérés en raison de la proximité des boutiques Amazon européennes et dépendent notamment des poids des marchandises commandées, du type de produit et du vendeur.\n\nVous retrouverez le prix des produits en allant sur la boutique Amazon du produit que vous désirez. Parfois, aucun frais de livraison ne sont appliqués lors de vos achats, profitez donc-en 😉\n\nEnfin, il n'y a pas de droits de douane dans l'Union européenne lorsque les marchandises sont également commandées dans l'UE. C'est donc idéal pour le consommateur.`,
      text: "short-text",
    },
    {
      question: "Comment utiliser Charlize ?",
      response: `Lorsque vous utilisez notre plateforme, vous pouvez facilement rechercher le produit souhaité en utilisant notre barre de recherche. Celle-ci reste toujours accessible, même lorsque vous faites défiler la page, grâce à des mots-clés pertinents.\n\nUne fois que vous avez trouvé le produit qui vous convient, vous pouvez choisir de l'acheter via notre site en cliquant sur le pays correspondant. Cela vous redirigera vers la boutique Amazon européenne proposant le produit que vous avez sélectionné.\n\nUne fois redirigé vers la boutique Amazon, vous pouvez suivre le processus d'achat habituel de cette plateforme. Vous bénéficiez ainsi de la confiance et de la sécurité offertes par Amazon lors de votre transaction.\n\nEn résumé, notre plateforme vous permet de trouver facilement vos produits préférés, de comparer les prix et de vous rediriger vers la boutique Amazon.`,
      text: "long-text",
    },
    {
      question: "Votre droit de rétractation",
      response: `Le droit de l'Union européenne vous permet, comme en France, d'avoir la possibilité d'annuler vos commandes en ligne dans un délai de 14 jours et de renvoyer l'article. Pour exercer ce droit, vous devez contacter la boutique dans laquelle vous avez effectué votre achat.\n\nLorsque vous achetez dans un autre magasin Amazon européen que celui auquel vous êtes habitué, vous ne prenez donc pas de risque. Vous bénéficiez des mêmes droits de rétractation pour vos achats en ligne. C'est absolument pareil que dans votre magasin préféré dans lequel vous allez tous les samedis.`,
      text: "short-text",
    },
    {
      question: "Astuce de traduction",
      response: `Si vous souhaitez traduire le contenu de la boutique désirée, voici quelques astuces selon votre navigateur :
  - Pour Firefox : Vous pouvez utiliser l'option de traduction intégrée à Firefox. Lorsque vous visitez la boutique, un message vous proposera de traduire automatiquement la page.
  - Pour Google Chrome : Vous pouvez utiliser la fonction de traduction automatique de Google Chrome. Lorsque vous accédez à la boutique, un message de traduction s'affiche en haut de la page et vous pouvez choisir de traduire la page dans votre langue préférée.
  - Pour Safari : Vous pouvez utiliser l'extension de traduction de Safari. Vous pouvez trouver des extensions telles que Translate dans la galerie d'extensions de Safari. Une fois installée, l'extension vous permettra de traduire le contenu de la boutique en un seul clic.
  
  Ces astuces vous aideront à traduire le contenu de la boutique.`,
      text: "long-text",
    },
  ];

  return (
    <>
      <Modal />
      <Header />
      <h3 className="pageTitle">F.A.Q</h3>
      <div className="questions">
        {questions.map((question, index) => {
          return <Question question={question} key={index} />;
        })}
      </div>
      <Lights />
      <Footer />
    </>
  );
};

export default FAQ;
