import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import MentionLegales from "./Pages/MentionLegales";
import Favorite from "./Pages/Favorite";
import Cookies from "./Pages/Cookies";
import Social from "./Pages/Social";
import FAQ from "./Pages/FAQ";
import Error404 from "./Pages/Error404";
import PolitiquesDeConf from "./Pages/PolitiquesDeConf";
import ConditionsUtils from "./Pages/ConditionsUtils";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/mentionslegales" element={<MentionLegales />} />
        <Route path="/politiques-de-conf" element={<PolitiquesDeConf />} />
        <Route path="/conditions-utils" element={<ConditionsUtils />} />
        <Route path="/favorites" element={<Favorite />} />
        <Route path="/reseaux" element={<Social />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
