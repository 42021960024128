import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import Lights from "../Components/Lights";

const Mention = () => {
  return (
    <div className="mentions">
      <Modal />
      <Header />
      <h3 className="pagesTitle">Mentions Légales</h3>
      <p>
        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
        la confiance en l'économie numérique, il est précisé aux utilisateurs du
        site Charlize l'identité des différents intervenants dans le cadre de sa
        réalisation et de son suivi.
      </p>
      <p>Le Directeur de la publication du Site est GLOAGUEN Kiliann.</p>
      <ul>
        Le présent site, accessible à l’URL (le « Site »), est édité par :
        <li>CharlesDev_</li>
        <li>charlize.contacter@gmail.com</li>
      </ul>
      <p>
        L'hébergeur est accessible sur le site de o2switch, voici son siège: CHE
        DES PARDIAUX 63000 CLERMONT-FERRAND France
      </p>
      <Lights />
      <Footer />
    </div>
  );
};

export default Mention;
