import React from "react";
import Card from "./Card";

const Menu = ({ data, homePage }) => {
  return (
    <section className="mainMenu">
      {homePage ? (
        <h3>Les meilleures ventes </h3>
      ) : (
        <h3 className="searchResult">Les résultats de votre recherche :</h3>
      )}
      <div className="cards">
        {data && data.length !== 0 ? (
          data.map((item, index) => <Card key={index} data={item} />)
        ) : (
          <div className="custom-loader"></div>
        )}
      </div>
    </section>
  );
};

export default Menu;
