import React, { useState, useEffect } from "react";
import { Squash as Hamburger } from "hamburger-react";

const Modal = () => {
  const [isOpen, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={isOpen ? "background-on-modal" : ""}
        onClick={() => setOpen(false)}
      ></div>
      <section className={isOpen ? "modal-open" : "modal-hide"}>
        <Hamburger toggled={isOpen} toggle={handleToggle} />
        <ul>
          <li>
            <a href="/" className="modal-link " onClick={() => setOpen(false)}>
              Accueil
            </a>
          </li>
          <li>
            <a
              href="/faq"
              className="modal-link"
              onClick={() => setOpen(false)}
            >
              F.A.Q
            </a>
          </li>
          <li>
            <a
              href="/reseaux"
              className="modal-link"
              onClick={() => setOpen(false)}
            >
              Réseaux
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Modal;
