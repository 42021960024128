import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="information">
        <ul>
          <li id="name">Charlize</li>
          <li>
            <a href="/">Accueil</a>
          </li>
          <li>
            <a href="/favorites">Favoris</a>
          </li>
          <li>
            <a href="/reseaux">Réseaux</a>
          </li>
          <li>
            <a href="/faq">FAQ</a>
          </li>
          <li>
            <a href="/mentionslegales">Mentions légales</a>
          </li>
          <li>
            <a href="/cookies">Cookies</a>
          </li>

          <li>
            <a href="/conditions-utils">Conditions d'utilisations</a>
          </li>
          <li>
            <a href="/politiques-de-conf">Politiques de confidentialités</a>
          </li>
        </ul>
      </div>
      <p id="right">Charlize 2023 Tous droits réservés®</p>
      <p id="affiliate">
        En tant qu'associée d'Amazon, Charlize gagne de l'argent sur les achats
        qualifiés.
      </p>
      <p id="dev">
        Vous recherchez un développeur web ? Contactez-moi sur ce mail{" "}
        <a href="mailto:lespepitesamz@gmail.com">
          <strong>lespepitesamz@gmail.com</strong>
        </a>
        !
      </p>
    </footer>
  );
};

export default Footer;
