import React from "react";
import Header from "../Components/Header";
import Lights from "../Components/Lights";
import Modal from "../Components/Modal";
import Footer from "../Components/Footer";

const Error404 = () => {
  return (
    <>
      <Modal />
      <Header />
      <div className="error404">Oups.. cette page n'existe pas.</div>
      <Lights />
      <Footer />
    </>
  );
};

export default Error404;
