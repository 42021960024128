import React from "react";

const Lights = () => {
  return (
    <>
      <div className="lights" id="light1"></div>
      <div className="lights" id="light2"></div>
      <div className="lights" id="light3"></div>
    </>
  );
};

export default Lights;
