import React, { useState } from "react";
import Header from "../Components/Header";
import { useEffect } from "react";
import FavoriteCard from "../Components/FavoriteCard";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import Lights from "../Components/Lights";
import NotificationBar from "../Components/NotificationBar";

const Favorite = () => {
  const [dataFavorite, setDataFavorite] = useState([]);

  useEffect(() => {
    const products = JSON.parse(localStorage.getItem("products")) || [];
    setDataFavorite(products);
  }, []);

  const removeFavorite = (id) => {
    const updatedFavorites = dataFavorite.filter(
      (favorite) => favorite.id !== id
    );
    localStorage.setItem("products", JSON.stringify(updatedFavorites));
    setDataFavorite(updatedFavorites);
  };
  return (
    <>
      <Modal />
      <Header />
      <NotificationBar />
      <h3 className="favorite">Vos favoris</h3>
      <div className="cards">
        {dataFavorite && dataFavorite.length !== 0 ? (
          dataFavorite.map((data, index) => (
            <FavoriteCard
              key={index}
              data={data}
              removeFavorite={removeFavorite}
            />
          ))
        ) : (
          <div className="noFavorite">
            Oups.. Il semble que vous n'avez pas de favoris
          </div> // Contenu à afficher lorsque dataFavorite est vide
        )}
      </div>
      <Lights />
      <Footer />
    </>
  );
};

export default Favorite;
