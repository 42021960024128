import React, { useState } from "react";

const Question = ( { question } ) => {
    const [modalIsOpen , setModalIsOpen] = useState(false);
    const [notFirstConnect , setNotFirstConnect] = useState(false);

  return (
    <>
      <div className={
    !modalIsOpen && !notFirstConnect
      ? "questionElement"
      : modalIsOpen && notFirstConnect
      ? "questionElement animation-modal-down"
      : "questionElement animation-modal-up"
  } onClick={() => {setModalIsOpen
        (!modalIsOpen);setNotFirstConnect(true)}}>
        <div className="questionText">

          {question.question}
          <span className="material-symbols-outlined" onClick={() => setModalIsOpen
        (!modalIsOpen)}>
            {modalIsOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
          <p className={question.text}>{question.response}</p>
        </div>
    </>
  );
};

export default Question;
