import React from "react";
import Header from "../Components/Header";
import Modal from "../Components/Modal";
import Lights from "../Components/Lights";
import Footer from "../Components/Footer";

const Social = () => {
  return (
    <>
      <Modal />
      <Header />
      <h3 className="pagesTitle">Mes réseaux sociaux</h3>
      <div className="social-media">
        <div className="social-tiktok">
          <img
            src="tiktok_logo.png"
            alt="Logo de TikTok"
            className="social-logo"
          />
          <a
            href="https://www.tiktok.com/@charlesgln"
            target="_blank"
            className="social-link"
          ></a>
          <p className="social-username">@charlesgln</p>
        </div>
        <div className="social-facebook">
          <img
            src="facebook_logo.png"
            alt="Logo de Facebook"
            className="social-logo"
          />
          <a
            href="https://www.facebook.com/profile.php?id=100087399402749"
            target="_blank"
            className="social-link"
          ></a>
          <p className="social-username">@LesPépites</p>
        </div>
        <div className="social-instagram">
          <img
            src="instagram_logo.png"
            alt="Logo de Instagram"
            className="social-logo"
          />
          <a
            href="https://www.instagram.com/charlesgln__/"
            target="_blank"
            className="social-link"
          ></a>
          <p className="social-username">@charlesgln__</p>
        </div>
        <div className="social-youtube">
          <img
            src="youtube_logo.png"
            alt="Logo de Youtube"
            className="social-logo"
          />
          <a
            href="https://youtube.com/@lespepites461"
            target="_blank"
            className="social-link"
          ></a>
          <p className="social-username">@lespepites461</p>
        </div>
      </div>
      <Lights />
      <Footer />
    </>
  );
};

export default Social;
