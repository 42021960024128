import React from "react";

const FavoriteCard = ({ data, removeFavorite }) => {
  if (!data || data.length === 0) {
    return <div className="custom-loader"></div>;
  }
  console.log(data);
  return (
    <div className="card">
      <div className="product-image">
        <img src={data.img} alt={data.title} className="cardImg" />
      </div>
      <span
        className="material-symbols-outlined delete"
        onClick={() => removeFavorite(data.id)}
      >
        delete
      </span>
      <h4>{data.title}</h4>

      {data.priceinfo.map((price, index) => {
        return (
          <div key={index} className="raw">
            <img
              src={`flag${price.country}.png`}
              alt={`flag${price.country}`}
              className="countryFlag"
            />
            <div className="priceItem">{`${price.price} €`}</div>
            <div className="arrowRedirect button">
              <a href={price.url} target="_blank" rel="noreferrer">
                Redirection sur amazon
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FavoriteCard;
