import React, { useEffect, useState } from "react";
import heartAnim from "../assets/heart-anim.svg";

const Card = ({ data }) => {
  const [animationLike, setAnimationLike] = useState(false);
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  useEffect(() => {
    const existingProducts = JSON.parse(localStorage.getItem("products")) || [];
    setFavoriteProducts(existingProducts);
    const hasProductWithTheSameId = existingProducts.some(
      (product) => product.id === data.id
    );
    if (hasProductWithTheSameId) {
      setAnimationLike(true);
    }
  }, []);

  const handleFavorite = () => {
    const existingFavorites =
      JSON.parse(localStorage.getItem("products")) || [];
    if (!animationLike) {
      const product = {
        priceinfo: data.prices,
        img: data.imageUrl,
        title: data.title,
        id: data.id,
      };
      const updatedProducts = [...existingFavorites, product];
      localStorage.setItem("products", JSON.stringify(updatedProducts));
    } else {
      const updatedFavorites = existingFavorites.filter(
        (favorite) => favorite.id !== data.id
      );
      localStorage.setItem("products", JSON.stringify(updatedFavorites));
      setFavoriteProducts(updatedFavorites);
    }
  };

  return (
    <div className="card">
      <div className="product-image">
        <img src={data.imageUrl} alt={data.title} className="cardImg" />
      </div>
      <div className="icons">
        <span
          className="material-symbols-outlined heart"
          onClick={() => {
            handleFavorite();
            setAnimationLike(!animationLike);
          }}
        >
          favorite
          <img
            src={heartAnim}
            alt="heartAnim"
            className={
              animationLike
                ? "heart-animation heart-start-animation"
                : "heart-animation"
            }
          />
        </span>
      </div>
      <h4>{data.title}</h4>

      {data.prices.map((price, index) => {
        return (
          <div key={index} className="raw">
            <img
              src={`flag${price.country}.png`}
              alt={`flag${price.country}`}
              className="countryFlag"
            />
            <div className="priceItem">{`${price.price} €`}</div>
            <div className="arrowRedirect button">
              <a href={price.url} target="_blank" rel="noreferrer">
                Redirection sur amazon
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Card;
