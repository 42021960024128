import React from "react";
import chromeSvg from "../assets/chrome.svg";
import firefoxSvg from "../assets/firefox.svg";

const NotificationBar = () => {
  return (
    <div className="notification-bar">
      L'extension Charlize est maintenant disponible sur les plateformes !
      Essayez-la dès maintenant
      <div className="buttons">
        <div className="button-extension">
          <a
            href="https://chrome.google.com/webstore/detail/charlize-comparateur-de-p/ldampcdnpgokadoofhhdjjklcfoiplbh/related?utm_source=ext_sidebar&hl=fr"
            target="blank_"
          ></a>
          <img src={chromeSvg} alt="Logo de Chrome" />
          Chrome Extension
        </div>
        <div className="button-extension">
          <a
            href="https://addons.mozilla.org/fr/firefox/addon/charlize/"
            target="blank_"
          ></a>
          <img src={firefoxSvg} alt="Logo de Firefox" />
          Firefox Extension
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
