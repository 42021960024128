import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import Lights from "../Components/Lights";

const Cookies = () => {
  return (
    <div className="cookie">
      <Modal />
      <Header />
      <h3 className="pagesTitle">Cookies</h3>
      <p>
        Politique en matière de cookies 🍪 <br></br>Conformément à l'article
        22.2 de la loi 34/2002, du 11 juillet, sur les services d'information
        société et commerce électronique, Charlize informe ses utilisateurs de
        l'utilisation de cookies pour améliorer le service fourni.<br></br> Que
        sont les cookies ? Un cookie est un fichier téléchargé sur l'équipement
        terminal de l'utilisateur lorsqu'il accède à certains les pages Web. Les
        cookies permettent, entre autres, à une page Web de stocker et de
        récupérer des informations sur les habitudes de navigation d'un
        utilisateur ou de son équipe afin que, selon le type de navigation, il
        peut offrir un meilleur service via la page Web.<br></br>Quel type de
        cookies ce site Web utilise-t-il et quelles sont leurs finalités ?
        Technique Les cookies techniques permettent à l'utilisateur de naviguer
        sur le site Web et d'utiliser les différentes options ou des services
        qu'il propose.
        <br></br>Par exemple : contrôler le trafic et la communication des
        données, identifier la session, etc. Personnalisation Les cookies de
        personnalisation permettent à l'utilisateur d'accéder au service avec
        certains paramètres généraux prédéfinis, les caractéristiques. Par
        exemple, la langue ou le type de navigateur. Analytique Les cookies
        analytiques sont ceux qui permettent au responsable des cookies de
        surveiller et analyser le comportement des utilisateurs du site Web. Ils
        sont utilisés pour mesurer l'activité du site Web et pour créer des
        profils de navigation utilisateur. Publicité Les cookies publicitaires
        sont ceux qui permettent une gestion plus efficace de la publicité
        espaces que l'éditeur a ajoutés sur une page Web. <br></br>Combien de
        temps les cookies restent-ils actifs ? Les cookies sur ce site Web
        peuvent rester actifs aussi longtemps que la personne qui en est
        responsable détermine, étant classés comme cookies persistants.
        <br></br>Qui gère les cookies sur ce site ? Les cookies techniques et de
        personnalisation sont gérés par le propriétaire du site Web, tandis que
        les Les cookies analytiques et publicitaires utilisés sur ce site sont
        gérés par un tiers, en ce sens cas, Google Analytics. Vous pouvez
        obtenir plus d'informations sur le fonctionnement des cookies de Google
        Analytics via les éléments suivants. Autoriser, bloquer ou supprimer les
        cookies Tout utilisateur peut autoriser, bloquer ou supprimer les
        cookies installés sur son équipement terminal en configuration des
        options du navigateur installé. Pour toute question concernant notre
        politique en matière de cookies, vous pouvez nous contacter via l'e-mail
        suivant charlize.contacter@gmail.com
      </p>
      <Lights />
      <Footer />
    </div>
  );
};

export default Cookies;
