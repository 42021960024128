import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import Lights from "../Components/Lights";

const ConditionsUtils = () => {
  return (
    <div className="mentions">
      <Modal />
      <Header />
      <h3 className="pagesTitle">Conditions d'utilisations</h3>
      <p>
        Conditions d'Utilisation de Charlize Acceptation des Conditions Les
        présentes conditions d'utilisation régissent l'accès et l'utilisation du
        contenu et des fonctionnalités du site Charlize (www.charlizeapp.com).
        En utilisant ce site, vous acceptez d'utiliser le contenu et les
        fonctionnalités selon ces termes gratuitement. Modifications des
        Conditions Charlize se réserve le droit de modifier ces conditions à
        tout moment. L'utilisation continue du site sera soumise à l'acceptation
        des conditions modifiées. Droits d'Auteur Le contenu disponible sur
        Charlize est protégé par les droits d'auteur. Ce contenu est fourni
        gratuitement pour un usage non commercial personnel, à condition que
        tous les avis de droits d'auteur soient conservés et que le contenu ne
        soit pas modifié de quelque manière que ce soit. L'utilisation
        commerciale ou publique du contenu, y compris son intégration dans des
        sites tiers via des liens, nécessite une autorisation écrite préalable
        de Charlize. Limitations de l'Utilisation L'utilisation de la fonction
        de recherche de produits est limitée à 18 accès toutes les 360 minutes.
        L'utilisation automatisée du site, par exemple via des robots ou des
        araignées, est strictement interdite. Modifications et Disponibilité du
        Site Charlize peut à tout moment modifier, interrompre ou supprimer tout
        ou partie du contenu et des fonctionnalités, ou cesser l'opération du
        site. Bien que nous nous efforcions d'assurer une disponibilité
        constante, nous ne garantissons pas un accès ininterrompu au site.
        Exclusion de Responsabilité Responsabilité du Contenu : Bien que nous
        ayons pris soin de créer le contenu de Charlize, nous ne pouvons
        garantir son exactitude, son exhaustivité ou sa mise à jour.
        Conformément aux lois en vigueur, nous sommes responsables de notre
        propre contenu. Cependant, nous ne sommes pas tenus de surveiller les
        informations transmises ou stockées ni de rechercher des indices
        d'activités illégales. Liens Externes : Charlize peut contenir des liens
        vers des sites tiers dont nous ne contrôlons pas le contenu. La
        responsabilité du contenu des sites liés incombe à leurs opérateurs
        respectifs. Un contrôle constant des pages liées sans preuves concrètes
        d'une violation n'est pas réalisable. En cas de notification de
        violations, ces liens seront supprimés immédiatement. Droits d'Auteur
        Externes Lorsque le contenu sur Charlize n'a pas été créé par nous, les
        droits d'auteur des tiers sont respectés. Si vous remarquez une
        violation du droit d'auteur, veuillez nous en informer et nous
        retirerons le contenu concerné. Prix et Offres Les prix sur Charlize
        sont mis à jour automatiquement. Cependant, des modifications peuvent
        survenir. Avant de finaliser un achat, vérifiez toujours les
        informations sur le site du vendeur. Participation au Programme
        d'Affiliation Amazon Charlize participe au programme d'affiliation
        Amazon EU, permettant de gagner des commissions publicitaires par la
        publicité et les liens vers Amazon. Crédits de Textes et d'Images Les
        descriptions et images des produits proviennent des sources suivantes :
        © Amazon (www.amazon.de, www.amazon.co.uk, www.amazon.es, www.amazon.fr,
        www.amazon.it) © Fabricants/Fournisseurs respectifs Amazon et son logo
        sont des marques déposées de Amazon.com, Inc. ou de ses filiales.
        Résolution des Litiges Pour la résolution des litiges en ligne, la
        Commission européenne propose une plateforme accessible sur
        https://ec.europa.eu/consumers/odr/. Charlize n'est ni tenue ni disposée
        à participer à une procédure de règlement des différends devant une
        commission d'arbitrage des consommateurs.
      </p>
      <Lights />
      <Footer />
    </div>
  );
};

export default ConditionsUtils;
